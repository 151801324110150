import { defaultTo, isNil, isString, merge } from 'lodash-es'

/*
Method to support other protocols later if needed,
like Pusher, WebSockets, etc.
*/
const getSendMessageApi = options => {
  switch (options.protocol) {
    case 'postMessage':
    default:
      return {
        handler: window.postMessage.bind(window.parent),
        params: [options.targetOrigin],
      }
  }
}

/*
By default we are using truthy stringifyMessage options
to be backward compatible to LWC implementation. Parse and
stringify is not needed in postMessage since objects are
transfferable anyways. Open link is common message so we
are handing it inside to reduce duplicated code.
*/
export const sendBusMessage = (message, options) => {
  if (isNil(message)) {
    console.warn('AP:InvalidBusMessageError', message)

    return false
  }

  const settings = merge(defaultTo(options, {}), {
    protocol: 'postMessage',
    targetOrigin: '*',
    stringifyMessage: true,
  })

  const api = getSendMessageApi(settings)
  const payload = settings.stringifyMessage ? JSON.stringify(message) : message

  if (isNil(api)) {
    console.warn('AP:InvalidBusApiError', settings.protocol)

    return false
  }

  if (message.action === 'openLink' && message.url) {
    const isTopFrame = window.top === window.self

    if (isTopFrame) {
      window.open(message.url)

      return true
    }
  }

  api.handler(payload, ...api.params)

  return true
}

export const setBusListeners = () => {
  window.addEventListener('message', event => {
    if (event.data) {
      /*
      Since there is no message type, we are using
      property detection to set message type.
      */
      let customEvent
      const isInitMessage = isString(event.data.userId)

      if (isInitMessage) {
        customEvent = new CustomEvent('Bus:Init', {
          detail: event.data,
        })
      }

      if (customEvent) {
        window.dispatchEvent(customEvent)
      }
    }
  })
}

import http from './http'
import { mapConnections } from './connections'

export const getHerokuAuth = token => {
  return http
    .$post('/admin/heroku/verify_auth_token', {
      heroku_auth_token: token,
    })
    .then(response => {
      return response.heroku_token
    })
}

export const getMe = () =>
  http.$get('/admin/users/me').then(response => {
    const stepperStatus = mapStepperStatusResponse(response.setup_steps)

    return {
      id: response.id,
      isSearchBeforeCreateEnabled: response.modules?.realtime?.search?.enabled,
      isImportEnabled: response.modules?.bulk?.import?.enabled,
      agents: response.agents,
      systemStatus: mapMiniStepperReponse(response.dashboard_steps),
      stepperStatus,
      connections: mapConnections(
        response.connections,
        stepperStatus.isHerokuRequired,
      ),
      enableXOBreakup: response.enable_xo_breakup,
    }
  })

export const getMiniStepperStatus = () => {
  return http
    .$get(`/datasystem/ministepper/status/`)
    .then(response => mapMiniStepperReponse(response))
}

function mapMiniStepperReponse(response) {
  return {
    allRequirementsDone:
      response.user_visited_layout &&
      response.user_visited_detect_and_merge &&
      response.crawler_finished,
    layoutsVisited: response.user_visited_layout,
    directorsVisited: response.user_visited_detect_and_merge,
    isFirstIndexingFinished: response.crawler_finished,
    isCrawlerReindexing: response.crawler_reindexing,
  }
}

export const setMiniStepperStatusLayoutsVisited = () => {
  return http.$post(`/datasystem/ministepper/status/`, {
    user_visited_layout: true,
  })
}
export const getStepperStatus = () => {
  return http
    .$get(`/datasystem/stepper/status/`)
    .then(response => mapStepperStatusResponse(response))
}

function mapStepperStatusResponse(response) {
  return {
    isHerokuRequired:
      response.enable_heroku === true || response.enable_heroku === undefined,
    isTermsAndConditionsAccepted: response.terms_details?.accepted,
    isFinished: response.status.finished,
    isSalesforcePackageInstalled: response.status.sfdc_package_installed,
    isConnectionsComplete:
      response.status.sfdc_service_auth_created &&
      response.status.heroku_service_auth_created,
    isCrawlerComplete: response.status.crawler_created,
    isMultimapComplete: response.status.multi_map_created,
    otpKey: response.otp_key,
    package: {
      urls: [
        {
          label: 'Home',
          url: response.widgets.dashboard,
        },
        {
          label: 'Duplicate Grid',
          url: response.widgets.duplicate_grid,
        },
        {
          label: 'Merge History',
          url: response.widgets.merge_history,
        },
      ],
    },
    packageInstallUrl: response.install_url,
  }
}

export const acceptTermsAndConditions = () => {
  return http.$post(`/datasystem/accept_terms`, { version: '1.0' })
}

export const resetSystemToInitialState = () => {
  return http.$post(`/admin/reset`, { reason: 'manually triggered from FE' })
}

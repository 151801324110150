import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, computed } from 'vue'
import { TransitionPresets, useTransition } from '@vueuse/core'
import { getMetrics } from '@/api/stats.js'
import { getMeta } from '@/api/metadata.js'

export const useStats = defineStore('stats', () => {
  const databaseIsClean = ref(false)

  const isLoadingMetadata = ref(false)
  const isMetadataError = ref(false)
  const issuesMetadata = ref(
    JSON.parse(localStorage.getItem('dashboardMetadata')),
  )

  const issuesMetrics = ref(null)
  const isLoadingIssuesStats = ref(false)
  const isIssuesStatsError = ref(false)

  const fixedRecordsCount = ref(0)
  const fixedRecordsCountAnimated = useTransition(fixedRecordsCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  const fixIgnoredCount = ref(0)
  const fixIgnoredCountAnimated = useTransition(fixIgnoredCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  const fixErrorsCount = ref(0)
  const fixErrorsCountAnimated = useTransition(fixErrorsCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  const undoFixErrorsCount = ref(0)
  const undoFixErrorsCountAnimated = useTransition(undoFixErrorsCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  const duplicatesCount = ref(0)
  const duplicatesCountAnimated = useTransition(duplicatesCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  const mergedDuplicatesCount = ref(0)
  const mergedDuplicatesCountAnimated = useTransition(mergedDuplicatesCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  const mergeErrorsCount = ref(0)
  const mergeErrorsCountAnimated = useTransition(mergeErrorsCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  const undoMergeErrorsCount = ref(0)
  const undoMergeErrorsCountAnimated = useTransition(undoMergeErrorsCount, {
    duration: 1000,
    transition: TransitionPresets.easeInOutCubic,
  })

  async function getMetadata() {
    isLoadingMetadata.value = true
    isMetadataError.value = false

    try {
      const metadata = await getMeta()
      issuesMetadata.value = metadata
      localStorage.setItem('dashboardMetadata', JSON.stringify(metadata))
    } catch (error) {
      isMetadataError.value = true
      throw error
    } finally {
      isLoadingMetadata.value = false
    }
  }

  const overviewChart = computed(() => {
    if (
      issuesMetrics.value &&
      Object.keys(issuesMetrics.value.overviewStats).length > 0
    ) {
      return {
        series: [
          issuesMetrics.value.overviewStats.clean,
          issuesMetrics.value.overviewStats.dirty,
        ],
        chartOptions: {
          chart: {
            height: 265,
            type: 'pie',
          },
          labels: ['Clean', 'Dirty'],
          colors: ['#224979', '#FCBB15'],
          legend: {
            position: 'bottom',
          },
        },
      }
    }

    return null
  })

  const duplicateSetsChart = computed(() => {
    if (issuesMetadata.value && issuesMetrics.value) {
      const colors = issuesMetrics.value.duplicateSetsToReview.map(item => {
        const objs = issuesMetadata.value.directors[item.director].objects
        return objs.map((el, index) => {
          return {
            offset: index === 0 ? 0 : 100 / (objs.length - index),
            color: issuesMetadata.value.objects[el].color,
            opacity: 1,
          }
        })
      })

      return {
        series: [
          {
            name: 'Duplicate Sets',
            data: issuesMetrics.value.duplicateSetsToReview.map(
              item => item.count,
            ),
          },
        ],
        chartOptions: {
          chart: {
            height: 265,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              columnWidth: 45,
              distributed: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.5,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: colors,
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: issuesMetrics.value.duplicateSetsToReview.map(item =>
              handleChartLabel(
                issuesMetadata.value.directors[item.director].name,
              ),
            ),
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        },
      }
    }

    return null
  })

  const mergedDuplicateSetsChart = computed(() => {
    if (issuesMetadata.value && issuesMetrics.value) {
      const hasSomeAutomaticMerge =
        issuesMetrics.value.mergedDuplicatesSets.some(
          item => item.countAuto > 0,
        )
      const hasSomeUndoneMerge = issuesMetrics.value.mergedDuplicatesSets.some(
        item => item.countUndone > 0,
      )

      if (hasSomeAutomaticMerge || hasSomeUndoneMerge) {
        return issuesMetrics.value.mergedDuplicatesSets.map(item => {
          const objs = issuesMetadata.value.directors[item.director].objects

          const colors1 = objs.map((el, index) => {
            return {
              offset: index === 0 ? 0 : 100 / (objs.length - index),
              color: issuesMetadata.value.objects[el].color,
              opacity: 1,
            }
          })

          const colors = [
            colors1,
            colors1.map(el => {
              return { ...el, color: el.color + 'BF' }
            }),
            colors1.map(el => {
              return { ...el, color: el.color + '80' }
            }),
          ]

          return {
            total: item.countManual + item.countAuto + item.countUndone,
            series: [
              {
                name: issuesMetadata.value.directors[item.director].name,
                data: [item.countManual, item.countAuto, item.countUndone],
              },
            ],
            chartOptions: {
              title: {
                text: issuesMetadata.value.directors[item.director].name,
              },
              chart: {
                height: 265,
                type: 'bar',
                stacked: false,
              },
              plotOptions: {
                bar: {
                  columnWidth: 45,
                  distributed: true,
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                },
              },
              dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  colors: ['#304758'],
                },
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: 'vertical',
                  shadeIntensity: 0.5,
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 1,
                  colorStops: colors,
                },
              },
              legend: {
                show: false,
              },
              xaxis: {
                categories: ['Manual', 'Automatic', 'Undone'],
                labels: {
                  style: {
                    fontSize: '12px',
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: function (val) {
                    return val.toFixed(0)
                  },
                },
              },
            },
          }
        })
      } else {
        const colors = issuesMetrics.value.mergedDuplicatesSets.map(item => {
          const objs = issuesMetadata.value.directors[item.director].objects
          return objs.map((el, index) => {
            return {
              offset: index === 0 ? 0 : 100 / (objs.length - index),
              color: issuesMetadata.value.objects[el].color,
              opacity: 1,
            }
          })
        })

        return {
          series: [
            {
              name: 'Merged Duplicate Sets',
              data: issuesMetrics.value.mergedDuplicatesSets.map(
                item => item.countManual,
              ),
            },
          ],
          chartOptions: {
            chart: {
              height: 265,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                columnWidth: 45,
                distributed: true,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ['#304758'],
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 0.5,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: colors,
              },
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: issuesMetrics.value.mergedDuplicatesSets.map(item =>
                handleChartLabel(
                  issuesMetadata.value.directors[item.director].name,
                ),
              ),
              labels: {
                style: {
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val.toFixed(0)
                },
              },
            },
          },
        }
      }
    }

    return null
  })

  function handleChartLabel(originalLabel) {
    const label = originalLabel

    var indices = []
    for (var i = 0; i < label.length; i++) {
      if (label[i] === ' ') indices.push(i + 1)
    }

    if (indices.length == 0) {
      return originalLabel
    } else {
      indices.push(label.length)
    }

    let labelSplitted = []

    let previousindex = 0

    let num = 30

    while (indices.length > 0) {
      const a = indices.filter(el => el <= num)
      indices = indices.filter(el => el > num)

      num = num + 30

      const index = a[a.length - 1]

      labelSplitted.push(
        originalLabel.substr(previousindex, index - previousindex),
      )
      labelSplitted = labelSplitted.map(el => el.trim())
      previousindex = index
    }

    return labelSplitted
  }

  const mergeErrorsChart = computed(() => {
    if (issuesMetadata.value && issuesMetrics.value) {
      const colors = issuesMetrics.value.mergeErrors.map(item => {
        const objs = issuesMetadata.value.directors[item.director].objects
        return objs.map((el, index) => {
          return {
            offset: index === 0 ? 0 : 100 / (objs.length - index),
            color: issuesMetadata.value.objects[el].color,
            opacity: 1,
          }
        })
      })

      return {
        series: [
          {
            name: 'Merge Errors',
            data: issuesMetrics.value.mergeErrors.map(item => item.count),
          },
        ],
        chartOptions: {
          chart: {
            height: 265,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              columnWidth: 45,
              distributed: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.5,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: colors,
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: issuesMetrics.value.mergeErrors.map(item =>
              handleChartLabel(
                issuesMetadata.value.directors[item.director].name,
              ),
            ),
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        },
      }
    }

    return null
  })

  const undoMergeErrorsChart = computed(() => {
    if (issuesMetadata.value && issuesMetrics.value) {
      const colors = issuesMetrics.value.undoMergeErrors.map(item => {
        const objs = issuesMetadata.value.directors[item.director].objects
        return objs.map((el, index) => {
          return {
            offset: index === 0 ? 0 : 100 / (objs.length - index),
            color: issuesMetadata.value.objects[el].color,
            opacity: 1,
          }
        })
      })

      return {
        series: [
          {
            name: 'Merge Errors',
            data: issuesMetrics.value.undoMergeErrors.map(item => item.count),
          },
        ],
        chartOptions: {
          chart: {
            height: 265,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              columnWidth: 45,
              distributed: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.5,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: colors,
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: issuesMetrics.value.undoMergeErrors.map(item =>
              handleChartLabel(
                issuesMetadata.value.directors[item.director].name,
              ),
            ),
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        },
      }
    }

    return null
  })

  const issuesByObjectsCharts = computed(() => {
    if (issuesMetadata.value && issuesMetrics.value) {
      return issuesMetrics.value.dataToUpdateByObject.map(object => {
        const colors = object.issues.map(
          issue => issuesMetadata.value.issueTypes[issue.issue].color,
        )

        return {
          series: [
            {
              name: issuesMetadata.value.objects[object.object].label,
              data: object.issues.map(issue => issue.count),
            },
          ],
          chartOptions: {
            title: {
              text: issuesMetadata.value.objects[object.object].label,
            },
            chart: {
              height: 265,
              type: 'bar',
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: 45,
                distributed: true,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ['#304758'],
              },
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: object.issues.map(
                issue => issuesMetadata.value.issueTypes[issue.issue].label,
              ),
              labels: {
                style: {
                  fontSize: '12px',
                },
                formatter: function (val) {
                  return val.split(' ')
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val.toFixed(0)
                },
              },
            },
          },
        }
      })
    }

    return null
  })

  const objectsByIssuesCharts = computed(() => {
    if (
      issuesMetadata.value &&
      issuesMetrics.value &&
      issuesMetrics.value.dataToUpdateByIssue.some(el => el.objects.length > 0)
    ) {
      return issuesMetrics.value.dataToUpdateByIssue.map(issue => {
        const colors = issue.objects.map(
          object => issuesMetadata.value.objects[object.object].color,
        )

        return {
          series: [
            {
              name: issuesMetadata.value.issueTypes[issue.issue].label,
              data: issue.objects.map(object => object.count),
            },
          ],
          chartOptions: {
            title: {
              text: issuesMetadata.value.issueTypes[issue.issue].label,
            },
            chart: {
              height: 265,
              type: 'bar',
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: 45,
                distributed: true,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ['#304758'],
              },
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: issue.objects.map(
                object => issuesMetadata.value.objects[object.object].label,
              ),
              labels: {
                style: {
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val.toFixed(0)
                },
              },
            },
          },
        }
      })
    } else {
      return Array(Object.keys(issuesMetadata.value.issueTypes).length).fill(
        null,
      )
    }
  })

  const fixedRecordsChart = computed(() => {
    if (issuesMetrics.value && issuesMetadata.value) {
      const hasSomeAutomaticFix = issuesMetrics.value.fixedIssues.some(
        item => item.countAuto > 0,
      )
      const hasSomeUndoneFix = issuesMetrics.value.fixedIssues.some(
        item => item.countUndone > 0,
      )

      if (hasSomeAutomaticFix || hasSomeUndoneFix) {
        return issuesMetrics.value.fixedIssues.map(object => {
          const colors = [
            issuesMetadata.value.objects[object.object].color,
            issuesMetadata.value.objects[object.object].color + 'BF',
            issuesMetadata.value.objects[object.object].color + '80',
          ]

          return {
            total: object.countManual + object.countAuto + object.countUndone,
            object: object.object,
            series: [
              {
                name: issuesMetadata.value.objects[object.object].label,
                data: [
                  object.countManual,
                  object.countAuto,
                  object.countUndone,
                ],
              },
            ],
            chartOptions: {
              title: {
                text: issuesMetadata.value.objects[object.object].label,
              },
              chart: {
                height: 265,
                type: 'bar',
                stacked: false,
              },
              colors,
              plotOptions: {
                bar: {
                  columnWidth: 45,
                  distributed: true,
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                },
              },
              dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  colors: ['#304758'],
                },
              },
              legend: {
                show: false,
              },
              xaxis: {
                categories: ['Manual', 'Automatic', 'Undone'],
                labels: {
                  style: {
                    fontSize: '12px',
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: function (val) {
                    return val.toFixed(0)
                  },
                },
              },
            },
          }
        })
      } else {
        const colors = issuesMetrics.value.fixedIssues.map(object => {
          return issuesMetadata.value.objects[object.object].color
        })

        return {
          series: [
            {
              name: 'Fixed Records',
              data: issuesMetrics.value.fixedIssues.map(
                item => item.countManual,
              ),
            },
          ],
          chartOptions: {
            chart: {
              height: 265,
              type: 'bar',
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: 45,
                distributed: true,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ['#304758'],
              },
            },
            legend: {
              show: false,
            },
            xaxis: {
              categories: issuesMetrics.value.fixedIssues.map(
                object => issuesMetadata.value.objects[object.object].label,
              ),
              labels: {
                style: {
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val.toFixed(0)
                },
              },
            },
          },
        }
      }
    }

    return null
  })

  const fixIgnoredChart = computed(() => {
    if (issuesMetrics.value && issuesMetadata.value) {
      const colors = issuesMetrics.value.fixErrors.map(object => {
        return issuesMetadata.value.objects[object.object].color
      })

      return {
        series: [
          {
            name: 'Fix Ignored',
            data: issuesMetrics.value.fixErrors.map(item => item.countManual),
          },
        ],
        chartOptions: {
          chart: {
            height: 265,
            type: 'bar',
          },
          colors: colors,
          plotOptions: {
            bar: {
              columnWidth: 45,
              distributed: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: issuesMetrics.value.fixErrors.map(
              object => issuesMetadata.value.objects[object.object].label,
            ),
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        },
      }
    }

    return null
  })

  const fixErrorsChart = computed(() => {
    if (issuesMetrics.value && issuesMetadata.value) {
      const colors = issuesMetrics.value.fixErrors.map(object => {
        return issuesMetadata.value.objects[object.object].color
      })

      return {
        series: [
          {
            name: 'Fix Errors',
            data: issuesMetrics.value.fixErrors.map(item => item.count),
          },
        ],
        chartOptions: {
          chart: {
            height: 265,
            type: 'bar',
          },
          colors: colors,
          plotOptions: {
            bar: {
              columnWidth: 45,
              distributed: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: issuesMetrics.value.fixErrors.map(
              object => issuesMetadata.value.objects[object.object].label,
            ),
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        },
      }
    }

    return null
  })

  const undoFixErrorsChart = computed(() => {
    if (issuesMetrics.value && issuesMetadata.value) {
      const colors = issuesMetrics.value.undoFixErrors.map(object => {
        return issuesMetadata.value.objects[object.object].color
      })

      return {
        series: [
          {
            name: 'Fix Errors',
            data: issuesMetrics.value.undoFixErrors.map(item => item.count),
          },
        ],
        chartOptions: {
          chart: {
            height: 265,
            type: 'bar',
          },
          colors: colors,
          plotOptions: {
            bar: {
              columnWidth: 45,
              distributed: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: issuesMetrics.value.undoFixErrors.map(
              object => issuesMetadata.value.objects[object.object].label,
            ),
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        },
      }
    }

    return null
  })

  async function getIssuesStats() {
    isLoadingIssuesStats.value = true
    isIssuesStatsError.value = false

    try {
      issuesMetrics.value = await getMetrics()

      if (
        !issuesMetrics.value.dataToUpdateByIssue.some(
          el => el.objects.length > 0,
        )
      ) {
        databaseIsClean.value = true
      }

      duplicatesCount.value = issuesMetrics.value.duplicateSetsToReview.reduce(
        (acc, item) => acc + item.count,
        0,
      )

      mergedDuplicatesCount.value =
        issuesMetrics.value.mergedDuplicatesSets.reduce(
          (acc, item) =>
            acc + item.countManual + item.countAuto + item.countUndone,
          0,
        )

      mergeErrorsCount.value = issuesMetrics.value.mergeErrors.reduce(
        (acc, item) => acc + item.count,
        0,
      )

      undoMergeErrorsCount.value = issuesMetrics.value.undoMergeErrors.reduce(
        (acc, item) => acc + item.count,
        0,
      )

      fixedRecordsCount.value = issuesMetrics.value.fixedIssues.reduce(
        (acc, item) => acc + item.countManual + item.countAuto,
        0,
      )

      // fixIgnoredCount.value = issuesMetrics.value.fixIgnored?.reduce(
      //   (acc, item) => acc + item.count,
      //   0,
      // );

      fixErrorsCount.value = issuesMetrics.value.fixErrors.reduce(
        (acc, item) => acc + item.count,
        0,
      )

      undoFixErrorsCount.value = issuesMetrics.value.undoFixErrors.reduce(
        (acc, item) => acc + item.count,
        0,
      )
    } catch (error) {
      isIssuesStatsError.value = true
      throw error
    } finally {
      isLoadingIssuesStats.value = false
    }
  }

  return {
    overviewChart,

    issuesMetadata,
    isLoadingMetadata,
    isMetadataError,

    databaseIsClean,
    issuesMetrics,
    isLoadingIssuesStats,
    isIssuesStatsError,
    objectsByIssuesCharts,
    issuesByObjectsCharts,

    fixedRecordsChart,
    fixedRecordsCountAnimated,

    fixErrorsChart,
    fixErrorsCountAnimated,

    undoFixErrorsChart,
    undoFixErrorsCountAnimated,

    fixIgnoredChart,
    fixIgnoredCountAnimated,

    duplicateSetsChart,
    duplicatesCountAnimated,

    mergedDuplicateSetsChart,
    mergedDuplicatesCountAnimated,

    mergeErrorsChart,
    mergeErrorsCountAnimated,

    undoMergeErrorsChart,
    undoMergeErrorsCountAnimated,

    getMetadata,
    getIssuesStats,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStats, import.meta.hot))
}

import { createRouter, createWebHistory } from 'vue-router'
import { useStats } from '@/stores/stats'
import { useSession } from '@/stores/session'
import { useConnections } from '@/stores/connections'
import { getHerokuAuth } from '@/api/auth'
import { SignJWT } from 'jose'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/issues',
      name: 'admin-data-issues',
      component: () => import('../views/AdminDataIssues.vue'),
      meta: {
        breadcrumbs: [
          {
            title: 'Home',
            to: { name: 'admin-home' },
            disabled: false,
          },
          { title: 'Data Issues', disabled: true },
        ],
      },
    },
    {
      path: '/',
      component: () => import('../views/AdminRoot.vue'),
      children: [
        {
          path: 'dashboard',
          name: 'admin-home',
          component: () => import('../views/AdminHome.vue'),
          meta: {
            title: 'Home',
          },
        },
        {
          path: 'duplicate-sets',
          name: 'admin-duplicate-sets',
          component: () => import('../views/AdminDuplicateSets.vue'),
          meta: {
            isSideBarHidden: true,
            breadcrumbs: [
              {
                title: 'Home',
                to: { name: 'admin-home' },
                disabled: false,
              },
              { title: 'Duplicate Sets', disabled: true },
            ],
          },
        },
        {
          path: 'audit-log',
          children: [
            {
              path: '',
              name: 'admin-audit-log',
              component: () => import('../views/AdminAuditLog.vue'),
              meta: {
                title: 'Audit Log',
              },
            },
            {
              path: 'merged',
              name: 'admin-audit-log-merged',
              component: () => import('../views/AdminAuditLogMerged.vue'),
              meta: {
                title: 'Merged Duplicate Sets',
                isSideBarHidden: true,
                breadcrumbs: [
                  {
                    title: 'Audit Log',
                    to: { name: 'admin-audit-log' },
                    disabled: false,
                  },
                  { title: 'Merged Duplicate Sets', disabled: true },
                ],
              },
            },
            {
              path: 'undo-merge-errors',
              name: 'admin-audit-log-undo-merge-errors',
              component: () =>
                import('../views/AdminAuditLogUndoMergeErrors.vue'),
              meta: {
                title: 'Undo Merge Errors List',
                isSideBarHidden: true,
                breadcrumbs: [
                  {
                    title: 'Audit Log',
                    to: { name: 'admin-audit-log' },
                    disabled: false,
                  },
                  { title: 'Undo Merge Errors List', disabled: true },
                ],
              },
            },
            {
              path: 'merge-errors',
              name: 'admin-audit-log-merge-errors',
              component: () => import('../views/AdminAuditLogMergeErrors.vue'),
              meta: {
                title: 'Merge Errors List',
                isSideBarHidden: true,
                breadcrumbs: [
                  {
                    title: 'Audit Log',
                    to: { name: 'admin-audit-log' },
                    disabled: false,
                  },
                  { title: 'Merge Errors List', disabled: true },
                ],
              },
            },
            {
              path: 'fixed',
              name: 'admin-audit-log-fixed',
              component: () => import('../views/AdminAuditLogFixed.vue'),
              meta: {
                title: 'Fixed Records',
                isSideBarHidden: true,
                breadcrumbs: [
                  {
                    title: 'Audit Log',
                    to: { name: 'admin-audit-log' },
                    disabled: false,
                  },
                  { title: 'Fixed Records', disabled: true },
                ],
              },
            },
            {
              path: 'fix-ignored',
              name: 'admin-audit-log-fix-ignored',
              component: () => import('../views/AdminAuditLogFixIgnored.vue'),
              meta: {
                title: 'Fix Ignored List',
                isSideBarHidden: true,
                breadcrumbs: [
                  {
                    title: 'Audit Log',
                    to: { name: 'admin-audit-log' },
                    disabled: false,
                  },
                  { title: 'Fix Ignored List', disabled: true },
                ],
              },
            },
            {
              path: 'fix-errors',
              name: 'admin-audit-log-fix-errors',
              component: () => import('../views/AdminAuditLogFixErrors.vue'),
              meta: {
                title: 'Fix Errors List',
                isSideBarHidden: true,
                breadcrumbs: [
                  {
                    title: 'Audit Log',
                    to: { name: 'admin-audit-log' },
                    disabled: false,
                  },
                  { title: 'Fix Errors List', disabled: true },
                ],
              },
            },
            {
              path: 'undo-fix-errors',
              name: 'admin-audit-log-undo-fix-errors',
              component: () =>
                import('../views/AdminAuditLogUndoFixErrors.vue'),
              meta: {
                title: 'Undo Fix Errors',
                isSideBarHidden: true,
                breadcrumbs: [
                  {
                    title: 'Audit Log',
                    to: { name: 'admin-audit-log' },
                    disabled: false,
                  },
                  { title: 'Undo Fix Errors', disabled: true },
                ],
              },
            },
          ],
        },
        {
          path: 'detect-and-merge',
          children: [
            {
              path: 'layouts',
              children: [
                {
                  path: '',
                  name: 'admin-detect-and-merge-layouts',
                  component: () =>
                    import('../views/AdminDetectAndMergeLayouts.vue'),
                  meta: {
                    title: 'Layouts',
                    breadcrumbs: [
                      {
                        title: 'Detect & Merge',
                        disabled: false,
                      },
                      { title: 'Layouts', disabled: true },
                    ],
                  },
                },
                {
                  path: ':layoutId',
                  name: 'admin-detect-and-merge-layouts-details',
                  component: () =>
                    import('../views/AdminDetectAndMergeLayoutsDetails.vue'),
                  meta: {
                    isSideBarHidden: true,
                  },
                },
              ],
            },
            {
              path: 'business-rules',
              children: [
                {
                  path: '',
                  name: 'admin-detect-and-merge-business-rules',
                  component: () =>
                    import('../views/AdminDetectAndMergeBusinessRules.vue'),
                  meta: {
                    title: 'Business Rules',
                    breadcrumbs: [
                      {
                        title: 'Detect & Merge',
                        disabled: false,
                      },
                      { title: 'Business Rules', disabled: true },
                    ],
                  },
                },
                {
                  path: ':id',
                  name: 'admin-detect-and-merge-business-rules-details',
                  component: () =>
                    import(
                      '../views/AdminDetectAndMergeBusinessRulesDetails.vue'
                    ),
                  meta: {
                    isSideBarHidden: true,
                    breadcrumbs: [
                      {
                        title: 'Detect & Merge',
                        disabled: false,
                      },
                      {
                        title: 'Business Rules',
                        to: { name: 'admin-detect-and-merge-business-rules' },
                        disabled: false,
                      },
                      { title: 'Details', disabled: true },
                    ],
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'detect-and-fix',
          children: [
            {
              path: 'rules',
              children: [
                {
                  path: '',
                  name: 'admin-data-dimensions-rules',
                  component: () =>
                    import('../views/AdminDetectAndFixRules.vue'),
                  meta: {
                    title: 'Data Issues Rules',
                    breadcrumbs: [
                      {
                        title: 'Data Dimensions',
                        disabled: false,
                      },
                      { title: 'Data Issues Rules', disabled: true },
                    ],
                  },
                },
                {
                  path: ':directorId',
                  name: 'admin-detect-and-fix-objects-rules',
                  component: () =>
                    import('../views/AdminDetectAndFixRulesDetails.vue'),
                  meta: {
                    isSideBarHidden: true,
                    breadcrumbs: [
                      {
                        title: 'Detect & Fix',
                        disabled: false,
                      },
                      { title: 'Rules', disabled: true },
                    ],
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'search-before-create',
          children: [
            {
              path: 'layouts',
              name: 'admin-search-before-create-layouts',
              component: () =>
                import('../views/AdminSearchBeforeCreateLayouts.vue'),
              meta: {
                title: 'Layouts',
                breadcrumbs: [
                  {
                    title: 'Search Before Create',
                    disabled: false,
                  },
                  { title: 'Layouts', disabled: true },
                ],
              },
            },
            {
              path: 'business-rules',
              children: [
                {
                  path: '',
                  name: 'admin-search-before-create-business-rules',
                  component: () =>
                    import('../views/AdminSearchBeforeCreateBusinessRules.vue'),
                  meta: {
                    title: 'Business Rules',
                    breadcrumbs: [
                      {
                        title: 'Search Before Create',
                        disabled: false,
                      },
                      { title: 'Business Rules', disabled: true },
                    ],
                  },
                },
                {
                  path: ':id',
                  name: 'admin-search-before-create-business-rules-details',
                  component: () =>
                    import(
                      '../views/AdminSearchBeforeCreateBusinessRulesDetails.vue'
                    ),
                  meta: {
                    isSideBarHidden: true,
                    breadcrumbs: [
                      {
                        title: 'Search Before Create',
                        disabled: false,
                      },
                      {
                        title: 'Business Rules',
                        to: {
                          name: 'admin-search-before-create-business-rules',
                        },
                        disabled: false,
                      },
                      { title: 'Details', disabled: true },
                    ],
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'processes',
          name: 'admin-processes',
          component: () => import('../views/AdminProcesses.vue'),
          meta: {
            title: 'Processes',
          },
        },
        {
          path: '/general-settings',
          name: 'admin-general-settings',
          component: () => import('../views/AdminGeneralSettingsSetup.vue'),
        },
      ],
    },
    {
      path: '/general-settings/heroku',
      name: 'admin-general-settings-setup',
      component: () => import('../views/AdminGeneralSettingsSetup.vue'),
    },
    {
      path: '/find-duplicates',
      name: 'find-duplicates',
      component: () => import('../views/FindDuplicates.vue'),
    },
    {
      path: '/record-audit-trail',
      name: 'record-audit-trail',
      component: () => import('../views/RecordAuditTrail.vue'),
    },
    {
      path: '/sbc',
      name: 'search-before-create',
      component: () => import('../views/SearchBeforeCreate.vue'),
    },
  ],
})

router.beforeEach(async (to, from) => {
  const sessionStore = useSession()
  const statsStore = useStats()
  const connectionsStore = useConnections()

  if (!from.name) {
    sessionStore.isStartingPageLoading = true

    if (to.name === 'admin-general-settings-setup') {
      const code = to.query.heroku_auth_token

      if (code) {
        const newQueryParams = { ...to.query }
        delete newQueryParams.heroku_auth_token

        const herokuToken = await getHerokuAuth(code)
        sessionStore.setHerokuToken(herokuToken)

        return { path: to.path, query: newQueryParams }
      } else if (import.meta.env.VITE_IS_STANDALONE === 'true') {
        sessionStore.setHerokuToken(localStorage.getItem('apiKey'))
      }
    } else {
      const contextMessage = JSON.parse(localStorage.getItem('contextMessage'))

      if (contextMessage) {
        const token = await generateJwt(contextMessage)

        sessionStore.setJWT(token)
        sessionStore.setContextMessage(contextMessage)

        try {
          if (
            sessionStore.user &&
            !connectionsStore.isSomeConnectionDisconnected &&
            !connectionsStore.isSomeConnectionMissing
          ) {
            sessionStore.getUser()
          } else {
            await sessionStore.getUser()
          }

          if (
            !connectionsStore.isSomeConnectionDisconnected &&
            !connectionsStore.isSomeConnectionMissing
          ) {
            if (
              !sessionStore.fieldsPerObject ||
              !sessionStore.fieldsPerMultimap ||
              !sessionStore.operators
            ) {
              await sessionStore.getSystemMetadata()
            } else {
              sessionStore.getSystemMetadata()
            }

            // When setup is not finished this info won't be available
            // so we have to get it again after the setup (SF only) is finished
            if (statsStore.issuesMetadata) {
              statsStore.getMetadata()
            } else {
              await statsStore.getMetadata()
            }

            if (to.name === 'admin-home') {
              sessionStore.getRolesList()
            }

            // all the following information will be obtained during the setup flow
            if (
              !sessionStore.stepper.isFinished &&
              !sessionStore.stepper.isHerokuRequired
            ) {
              return { name: 'admin-general-settings-setup' }
            } else {
              if (sessionStore.stepper.isHerokuRequired) {
                connectionsStore.getConnectionObjects(
                  connectionsStore.herokuConnection.id,
                )
              } else {
                connectionsStore.getConnectionObjects(
                  connectionsStore.salesforceConnection.id,
                )
              }
            }
          }
        } catch {
          console.log('router error')
        }
      }
    }
  }
})

router.afterEach(() => {
  const sessionStore = useSession()

  sessionStore.isStartingPageLoading = false
})

async function generateJwt(message) {
  const payload = {
    org_id: message.orgId,
    session_id: message.sessionId,
    permission_name: message.permissionName,
    user_id: message.userId,
    user_email: message.email,
    user_first_name: message.firstName,
    user_last_name: message.lastName,
    user_name: message.userName,
    totp: message.totp,
    data: {
      entity_key: message.data.entityKey,
      entity_name: message.data.entityName,
    },
  }

  const secret = new TextEncoder().encode(import.meta.env.VITE_APP_JWS_SECRET)

  return await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .sign(secret)
}

export default router

import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useNotifications = defineStore('notifications', () => {
  const notifications = ref([])

  function setSuccessNotificationMessage(message) {
    notifications.value.push({
      id: notifications.value.length + 1,
      isSuccess: true,
      message,
    })
  }

  function setErrorNotificationMessage(
    message = 'Something went wrong. Please try again later!',
  ) {
    notifications.value.push({
      id: notifications.value.length + 1,
      isSuccess: false,
      message,
    })
  }

  return {
    notifications,
    setSuccessNotificationMessage,
    setErrorNotificationMessage,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotifications, import.meta.hot))
}

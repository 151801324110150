import http from './http'

export const systemTypes = [
  {
    code: 'heroku',
    label: 'Heroku',
  },
  {
    code: 'sfdc',
    label: 'Salesforce',
  },
]

const mapConnectionResponse = connection => {
  let auth = {}

  if (connection.system_type === 'sfdc') {
    auth = {
      url: connection.auth_params.url,
      username: connection.auth_params.user_name,
    }
  } else if (connection.system_type === 'heroku') {
    auth = {
      database: connection.auth_params.database,
      host: connection.auth_params.host,
      port: connection.auth_params.port,
      schema: connection.auth_params.schema,
      username: connection.auth_params.user,
    }
  }

  return {
    id: connection.id,
    name: connection.name,
    systemType: connection.system_type,
    systemTypeLabel: systemTypes.find(
      type => type.code === connection.system_type,
    )?.label,
    description: connection.description,
    modifiedAt: connection.modified,
    status: connection.status,
    isConnected: connection.status === 'connected',
    authStatus: connection.auth_status,
    ...auth,
  }
}

export function mapConnections(response, isHerokuRequired) {
  const connections = []

  // Heroku connection
  if (isHerokuRequired) {
    const herokuConnection = response.find(el => el.system_type === 'heroku')
    let herokuConnectionMapped = {
      systemType: 'heroku',
      systemTypeLabel: 'Heroku',
      name: 'Heroku Connection',
      description: 'Heroku Connection',
      port: '5432',
      schema: 'salesforce',
      isMissing: true,
    }
    if (herokuConnection) {
      herokuConnectionMapped = mapConnectionResponse(herokuConnection)
    }
    connections.push(herokuConnectionMapped)
  }

  // Salesforce connection
  const salesforceConnection = response.find(el => el.system_type === 'sfdc')
  let salesforceConnectionMapped = {
    systemType: 'sfdc',
    systemTypeLabel: 'Salesforce',
    name: 'Salesforce Connection',
    description: 'Salesforce Connection',
    isMissing: true,
  }
  if (salesforceConnection) {
    salesforceConnectionMapped = mapConnectionResponse(salesforceConnection)
  }
  connections.push(salesforceConnectionMapped)

  return connections
}

const mapConnnectionPayload = connection => {
  let authParams = {}

  if (connection.systemType === 'heroku') {
    authParams = {
      user: connection.username,
      password: connection.password,
      host: connection.host,
      port: connection.port,
      database: connection.database,
      schema: connection.schema,
    }
  } else {
    authParams = {
      url: connection.url,
      user_name: connection.username,
      password: connection.password,
      security_token: connection.securityToken,
    }
  }

  return {
    name: connection.name,
    description: connection.description,
    system_type: connection.systemType,
    auth_params: authParams,
    auto_connect: true,
  }
}

export const createConnection = connection => {
  return http
    .$post('/datasystem/serviceauth/', mapConnnectionPayload(connection))
    .then(connection => {
      return mapConnectionResponse(connection)
    })
}

export const updateConnection = connection => {
  return http
    .$post(
      `/datasystem/serviceauth/${connection.id}`,
      mapConnnectionPayload(connection),
    )
    .then(connection => {
      return mapConnectionResponse(connection)
    })
}

export const disconnectConnection = id => {
  return http
    .$post(`/datasystem/serviceauth/${id}/disconnect`)
    .then(connection => {
      return mapConnectionResponse(connection)
    })
}

export const connectConnection = id => {
  return http
    .$post(`/datasystem/serviceauth/${id}/connect`)
    .then(connection => {
      return mapConnectionResponse(connection)
    })
}

export const removeConnection = id => {
  return http.$delete(`/datasystem/serviceauth/${id}`)
}

export const getConnectionObjects = connectionId => {
  return http
    .$get(`/datasystem/serviceauth/${connectionId}/meta/objects`, {
      service_auth_id: connectionId,
    })
    .then(response => {
      return response.objects.map(el => {
        return {
          name: el.name,
          label: el.label,
        }
      })
    })
}
